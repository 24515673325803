@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

/* Spinner */
.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: 5000; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #000 transparent #555 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

.spinnerBox {
    width: 10%;
    top: 40%;
    position: absolute;
    left: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Login page */
.login-page {
    background-image: url('assets/images/bg-img.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.dataTables_info{
    padding-top: 0.85em;
}
.dataTables_paginate{
text-align: right;
}
ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

* {
    font-family: 'Raleway';
}